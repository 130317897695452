import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Legal } from '../../layouts/Legal';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Legal title="Privacy Policy">{children}</Legal>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Thank you for visiting the Trybe website at try.be (the "website")`}</p>
    <p>{`Five Nines Digital Limited is a company incorporated in England and Wales, (no. 12201800) and whose registered address is 124 City Road, London, EC1V 2NX, United Kingdom.`}</p>
    <h2>{`Your Personal Information`}</h2>
    <p>{`Five Nines Digital Limited do not automatically capture or store any other personal data from visitors to the site, except to log the user's IP address and session information such as the duration of the visit to the site and the nature of the browser used. This information is used only for administration of the site system and in the compilation of statistics used by Five Nines Digital Limited to assess the use of the site. No attempt is made to track the IP Address.`}</p>
    <p>{`Your information will only be captured and stored if you have submitted an online form requesting to download content or have requested further information.`}</p>
    <h2>{`How Your Information Will Be Used`}</h2>
    <p>{`Where you provide personal information to Five Nines Digital Limited it will be used :-`}</p>
    <p>{`Only for the service you requested and in line with the Principles and Rights of The General Data Protection Regulation. Any intended changes to data use will be communicated to you prior to it taking place, dealing with, and responding to you in relation to completion of an online form or if you have requested to download content from the site utilising online forms;`}</p>
    <p>{`Personalising your visits to the website anonymously and developing the design and style of the website to improve the services provided to you;`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`We will contact you with Service and Operational notifications on a contractual basis and on a consent basis for product updates and general interest communications`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Conducting customer surveys`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Carrying out statistical, technical and logistical analysis`}</p>
      </li>
    </ul>
    <h2>{`Will You Disclose Any of My Personal Data to Third Parties?`}</h2>
    <p>{`No data is shared with any Third Parties other than those indicated in the Product and Service fact sheet on our Customer GDPR Portal. Please note that this portal is only available to Trybe Customers.`}</p>
    <p>{`Where there is an intention to share data, you will be notified who and why, prior to that taking place in order that you may respond if required. No data is stored outside the EEA.`}</p>
    <p>{`If you have any concerns about data privacy or wish to know more about information security within Trybe, please contact `}<a parentName="p" {...{
        "href": "mailto:compliance@try.be"
      }}>{`compliance@try.be`}</a></p>
    <h2>{`Brexit and EU Data Processing`}</h2>
    <p>{`Both the UK Government and our Co-Founders have recommended that should the UK leave the European Union without a withdrawal agreement for data, that the Standard Contractual Clauses are incorporated to contracts currently reliant on GDPR.`}</p>
    <p>{`Most customers are on our standard terms, which allow us to update our terms and conditions in such situations, there, in order to maintain a processing agreement with us, we will incorporate the SCCs to our customer contracts.`}</p>
    <h2>{`Legal Issues`}</h2>
    <p>{`We may disclose, but are not obliged to disclose, your personal information if required to do so by law or order of the court or if we believe that such action is necessary to prevent fraud or cyber crime or for the purpose of, or in connection with, any legal proceedings (including prospective legal proceedings) or for the purposes of obtaining legal advice or to protect us, our affiliates, our website or the rights (including the rights of privacy, confidentiality and reputation), property or personal safety of any person or otherwise in accordance with the current Data Protection Legislation.`}</p>
    <h2>{`Cookies`}</h2>
    <p>{`When you visit our website, we may automatically collect non-personal information about you, such as the type of browser you use and the way you arrived at our website (either direct or via some referring website). The collection of this information may involve the use of cookies and web beacons. A web beacon is a small graphic image placed on a web page that is designed to allow us to monitor traffic on the website. A cookie is a small text file that is placed on your computer's hard drive by your web browser when you first visit the website.`}</p>
    <p>{`You cannot be identified from the information collected in this way on its own and it is only used to assist us in providing an effective service on our website and to collect broad demographic information for aggregate use and to improve the website.`}</p>
    <p>{`Most browsers accept cookies automatically. You can erase or block cookies from your computer if you want to, but certain services may not work correctly or as fast or at all if you set your browser not to accept cookies.`}</p>
    <h2>{`Acceptable Use Policy - SaaS`}</h2>
    <p>{`This Acceptable Use Policy sets forth acceptable practices for using Trybe's dedicated SaaS Services. Available on request.`}</p>
    <p>{`This Document is the property of Five Nines Digital Ltd. Classification - Public`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      